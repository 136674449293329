<template>
	<div class="h100 flex flex-col">
		<div class="cl">
			<div class="z">
				<base-button type="default" @click.native="goBack">返回</base-button>
			</div>
			<div class="y">
				<base-button type="primary" @click.native="downloadTmp">下载导入模版</base-button>
				<base-button type="danger" @click.native="toClean">重新导入文件</base-button>
			</div>
		</div>
		<template v-if="formData.list.length > 0">
			<div class="mg-t-20 flex1 hide">
				<el-form :model="formData" label-width="0px" ref="form" size="mini" class="h100">
					<el-table :data="formData.list" border style="width: 100%" :row-style="rowStyle" size="mini" :default-expand-all="false" height="100%" ref="list">
						<el-table-column type="expand">
							<template slot-scope="props">
								<el-form-item label="牵头单位" label-width="100px" style="width: 800px;" prop="qtdw" required>
									<el-table :data="props.row.qtdw" :show-header="true" border size="mini" row-key="id">
										<el-table-column label="单位名称">
											<template slot-scope="scope">
												<el-form-item label="" :prop="'list.'+props.$index+'.qtdw.'+scope.$index+'.dept_id'" :rules="[{required:true,message:'请选择牵头单位'}]">
													<type-select v-model="scope.row.dept_id" url="/Dept/listAll" :data="{state:1,delete_time:0,orderBy:'id',sortBy:'asc',is_qtdw:1}"  :is-top="false" filterable style="width: 100%;" val-key="dept_id" @label="(e) => onChangeQtdwDept(e,props.$index,scope.$index)" @change="resetSubmit"></type-select>
												</el-form-item>
											</template>
										</el-table-column>
										<el-table-column label="领导">
											<template slot-scope="scope">
												<el-form-item label="" :prop="'list.'+props.$index+'.qtdw.'+scope.$index+'.lingdao_uid'" :rules="[{required:true,message:'请选择牵头单位的领导'}]">
													<type-select clearable v-model="scope.row.lingdao_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'lingdao'}" :placeholder="scope.row.dept_id ? '请选择领导' : '请先选择牵头单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'qtdw_lingdao_'+scope.$index" auto-first  @label="(e) => onChangeQtdwLingdao(e,props.$index,scope.$index)" @change="resetSubmit"></type-select>
												</el-form-item>
											</template>
										</el-table-column>
										<el-table-column label="联络员">
											<template slot-scope="scope">
												<el-form-item label="" :prop="'list.'+props.$index+'.qtdw.'+scope.$index+'.bsy_uid'" :rules="[{required:true,message:'请选择责任单位的联络员'}]">
													<type-select clearable v-model="scope.row.bsy_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'bsy'}" :placeholder="scope.row.dept_id ? '请选择联络员' : '请先选择责任单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'qtdw_bsy_'+scope.$index" auto-first  @label="(e) => onChangeQtdwBsy(e,props.$index,scope.$index)" @change="resetSubmit"></type-select>
												</el-form-item>
											</template>
										</el-table-column>
									</el-table>
								</el-form-item>
								<el-form-item label="责任单位" label-width="100px" style="width: 800px;" prop="zrdw">
									<el-table :data="props.row.zrdw" :show-header="true" border size="mini" row-key="id">
										<el-table-column label="单位名称">
											<template slot-scope="scope">
												<el-form-item label="" :prop="'list.'+props.$index+'.zrdw.'+scope.$index+'.dept_id'" :rules="[{required:true,message:'请选择责任单位'}]">
													<type-select v-model="scope.row.dept_id" url="/Dept/listAll" :data="{state:1,delete_time:0,orderBy:'id',sortBy:'asc',is_zrdw:1}" :key="'list.'+props.$index+'.zrdw.'+scope.$index+'.dept_id'"  :is-top="false" filterable style="width: 100%;" val-key="dept_id" @label="(e) => onChangeZrdwDept(e,props.$index,scope.$index)" @change="resetSubmit"></type-select>
												</el-form-item>
											</template>
										</el-table-column>
										<el-table-column label="领导">
											<template slot-scope="scope">
												<el-form-item label="" :prop="'list.'+props.$index+'.zrdw.'+scope.$index+'.lingdao_uid'" :rules="[{required:true,message:'请选择责任单位的领导'}]">
													<type-select clearable v-model="scope.row.lingdao_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'lingdao'}" :placeholder="scope.row.dept_id ? '请选择领导' : '请先选择责任单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'lingdao_'+scope.$index" auto-first :key="'list.'+props.$index+'.zrdw.'+scope.$index+'.lingdao_uid'" @label="(e) => onChangeZrdwLingdao(e,props.$index,scope.$index)" @change="resetSubmit"></type-select>
												</el-form-item>
											</template>
										</el-table-column>
										<el-table-column label="联络员">
											<template slot-scope="scope">
												<el-form-item label="" :prop="'list.'+props.$index+'.zrdw.'+scope.$index+'.bsy_uid'" :rules="[{required:true,message:'请选择责任单位的联络员'}]">
													<type-select clearable v-model="scope.row.bsy_uid" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',job:'bsy'}" :placeholder="scope.row.dept_id ? '请选择联络员' : '请先选择责任单位'" :pid="scope.row.dept_id" pid-data-key="dept_id" :auto-load="!!scope.row.dept_id" :is-top="false" filterable :multiple="false" style="width: 100%;"  right-key="job_str" :ref="'bsy_'+props.$index+'_'+scope.$index" auto-first :key="'list.'+props.$index+'.zrdw.'+scope.$index+'.bsy_uid'" @label="(e) => onChangeZrdwBsy(e,props.$index,scope.$index)" @change="resetSubmit"></type-select>
												</el-form-item>
											</template>
										</el-table-column>
										<el-table-column label="" width="100px">
											<template slot="header" slot-scope="scope">
												<el-button type="warning" size="mini" @click="toAddZrdw(props.$index)">新增</el-button>
											</template>
											<template slot-scope="scope">
												<el-button type="text" @click="toDelZrdw(props.$index,scope.$index)">删除</el-button>
											</template>
										</el-table-column>
									</el-table>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column type="index" label="序号" width="50px"></el-table-column>
						<el-table-column prop="error" label="错误" width="150px">
							<template slot-scope="scope">
								<div v-if="scope.row.error" style="line-height: 16px;">
									<div><i class="el-icon-error f-16" style="color: red;"></i></div>
									<div class="red">{{scope.row.error}}</div>
								</div>
								<div v-else style="line-height: 16px;">
									<i class="el-icon-success f-16" style="color: green;"></i><span style="color: green;">正确</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="title" label="标题" width="200px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.title'" :rules="[{required:true,message:'标题不能为空'}]">
									<el-input v-model="scope.row.title" style="width: 100%;" @input="resetSubmit"></el-input>
								</el-form-item>
							</template>
						</el-table-column>
<!--						<el-table-column prop="special" label="专题会议" width="320px">-->
<!--							<template slot-scope="scope">-->
<!--								<el-form-item :prop="'list.'+scope.$index+'.special_id'">-->
<!--									<base-tree-select clearable v-model="scope.row.special_id" url="/Special/listTree" :datas="{state:1,delete_time:0,orderBy:'sort',sortBy:'asc'}" val-label="title" style="width: 300px;" @choose="(e)=>onChooseSpecial(e,scope.$index)"></base-tree-select>-->
<!--								</el-form-item>-->
<!--							</template>-->
<!--						</el-table-column>-->
						<el-table-column prop="content" label="事项内容" width="200px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.content'">
									<el-input type="textarea" autosize placeholder="请输入内容" v-model="scope.row.content" @input="resetSubmit"></el-input>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="type_name" label="事项分类" width="120px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.type'" :rules="[{required:true,message:'事项分类必选'}]">
									<type-select clearable v-model="scope.row.type" :options="[{id:'ldjb',name:'批示督办'},{id:'zysx',name:'日常督办'},{id:'hysx',name:'会议督办'},{id:'qzfsx',name:'政务督办'}]" :is-top="false" style="width: 100px;" @label="(e) => onChangeType(e,scope.$index)" @change="resetSubmit"></type-select>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="dict_program" label="事项类型" width="150px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.dict_program_ids'" :rules="[{required:true,message:'事项类型必选'}]">
									<type-select v-model="scope.row.dict_program_ids" url="/SystemDict/listAll" :data="{type:'program',state:1,delete_time:0,orderBy:'sort',sortBy:'asc'}" :pid="scope.row.type" pid-data-key="akey" :auto-load="false"  :is-top="false" style="width: 100%;" @label="(e) => onChangeDictProgram(e,scope.$index)" @change="resetSubmit"></type-select>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="create_dept" label="交办单位" width="150px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.dept_id'" :rules="[{required:true,message:'交办单位必选'}]">
									<type-select v-model="scope.row.dept_id" url="/Dept/listAll" :data="{state:1,delete_time:0,orderBy:'id',sortBy:'asc',is_zrdw:0,is_qtdw:0}"  :is-top="false" filterable style="width: 100%;" val-key="dept_id" @label="(e) => onChangeCreateDept(e,scope.$index)" :key="'dept_'+scope.$index" @change="resetSubmit"></type-select>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="dby" label="督查员" width="120px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.dby_ids'" :rules="[{required:true,message:'督查员必选'}]">
									<type-select clearable v-model="scope.row.dby_ids" url="/User/listAll" :data="{state:1,delete_time:0,orderBy:'create_time',sortBy:'desc',dept_id:scope.row.dept_id,job:'dby'}" v-if="scope.row.dept_id"  filterable :pid="scope.row.dept_id" pid-data-key="akey" :auto-load="false"  :is-top="false" style="width: 100px;" right-key="job_str" auto-first :key="'dby_'+scope.$index" @label="(e) => onChangeDby(e,scope.$index)" @change="resetSubmit"></type-select>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="start_time" label="交办时间" width="150px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.start_time'" :rules="[{required:true,message:'交办时间必选'}]">
									<push-time now v-model="scope.row.start_time" style="width: 130px;" @change="(e) => onChangeStartTime(e,scope.$index)"></push-time>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column prop="end_time" label="办理时限" width="150px">
							<template slot-scope="scope">
								<el-form-item :prop="'list.'+scope.$index+'.end_time'" :rules="[{required:true,message:'办理时限必选'}]">
									<push-time v-model="scope.row.end_time" style="width: 130px;" :start-time="scope.row.start_time" @change="resetSubmit"></push-time>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="" fixed="right">
							<template slot-scope="scope">
								<del-button @click.native="delRow(scope.$index)" type="text"></del-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form>
			</div>
			<div class="text-center mg-t-15">
				<el-button type="danger" @click="toCheck">校验</el-button>
				<el-button type="primary" :disabled="!canSubmit" @click="submit">保存</el-button>
			</div>
		</template>
		<template v-else>
			<div class="text-center mg-t-50">
				<el-upload
					action="aaa"
					class="upload-demo"
					:before-upload="beforeUpload"
					:on-exceed="onExceed"
					drag
					accept=".xlsx,.xls"
					:multiple="false" :limit="1" :show-file-list="false">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传xlsx,xls文件，且不超过5Mb</div>
				</el-upload>
			</div>
		</template>
	</div>
</template>

<script>
import { read, utils } from 'xlsx'
export default {
	name: "import",
	data() {
		return {
			canSubmit:false,
			formData:{
				list:[]
			},
			tmpUrl:'',
			rowStyle:({row, rowIndex})=>{
				// console.log(row.error);
				if(row.error){
					return {backgroundColor:'#fbebeb'}
				}else{
					return {backgroundColor:'#edffed'}
				}
			},
			column: {
				'序号':'index',
				'专题会议':'special',
				'事项详述':'content',
				'事项一级分类':'type_name',
				'事项二级分类':'dict_program',
				'交办时间':'start_time',
				'办理期限':'end_time',
				'交办单位':'create_dept',
				'督办员':'dby',
				'牵头单位':'qtdw',
				'责任单位':'zrdw',
				'单位名称':'dept_name',
				'领导':'lingdao',
				'联络员':'bsy',
				'事项内容':'title'
			}
		}
	},
	created() {
	},
	mounted() {
		this.getSystem();
	},
	methods: {
		resetSubmit(){
			this.canSubmit = false;
		},
		submit(){
			let _this = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.formData.list));
					_this.$http.request({
						url:'/Program/multipleAdd',
						datas:_data,
						success(res){
							_this.$confirm('导入成功', '提示', {
								confirmButtonText: '继续导入',
								cancelButtonText: '返回',
								type: 'warning'
							}).then(() => {
								_this.formData.list = [];
							}).catch(() => {
								_this.goBack();
							});
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		toAddZrdw(pi){
			let _data = {dept_name:'',dept_id:'',lingdao:'',lingdao_uid:'',bsy:'',bsy_uid:''};
			this.formData.list[pi].zrdw.push(_data);
		},
		toDelZrdw(pi,si){
			this.formData.list[pi].zrdw.splice(si,1)
		},
		onChangeQtdwDept(e,pi,si){
			this.formData.list[pi].qtdw[si].dept_name = e ? e.name : '';
		},
		onChangeQtdwLingdao(e,pi,si){
			this.formData.list[pi].qtdw[si].lingdao = e ? e.name : '';
		},
		onChangeQtdwBsy(e,pi,si){
			this.formData.list[pi].qtdw[si].bsy = e ? e.name : '';
		},

		onChangeZrdwDept(e,pi,si){
			this.formData.list[pi].zrdw[si].dept_name = e ? e.name : '';
		},
		onChangeZrdwLingdao(e,pi,si){
			this.formData.list[pi].zrdw[si].lingdao = e ? e.name : '';
		},
		onChangeZrdwBsy(e,pi,si){
			this.formData.list[pi].zrdw[si].bsy = e ? e.name : '';
		},
		onChangeStartTime(e,i){
			// console.log(e);
			this.resetSubmit();
			if(this.formData.list[i].end_time){
				if(this.$moment(e).unix() > this.$moment(this.formData.list[i].end_time).unix()){
					this.formData.list[i].end_time = '';
				}
			}
		},
		onChooseSpecial(e,i){
			this.formData.list[i].special = e.title;
		},
		onChangeType(e,i){
			this.formData.list[i].type_name = e.name;
		},
		onChangeDictProgram(e,i){
			this.formData.list[i].dict_program = e.name;
		},
		onChangeCreateDept(e,i){
			this.formData.list[i].create_dept = e.name;
		},
		onChangeDby(e,i){
			this.formData.list[i].dby = e.name;
		},
		delRow(index){
			let _this = this;
			this.$confirm('是否删除第【'+(index+1)+'】行数据?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.formData.list.splice(index,1);
			}).catch(() => {});
		},
		toClean(){
			this.formData.list = []
		},
		downloadTmp(){
			if(this.tmpUrl){
				window.open(this.tmpUrl,'_blank');
			}
		},
		getSystem(){
			let _this = this;
			_this.$http.request({
				url:'/SystemSetting/getSystem',
				datas:{skey:'program_import_template'},
				success(res){
					console.log(res);
					_this.tmpUrl = _this.$utils.formatUrl(res.program_import_template);
				}
			})
		},
		goBack(){
			this.$user.removeTab();
			this.$router.back();
		},
		onExceed(files, fileList){
			this.$alert('只能选择一个文件', '提示', {
				confirmButtonText: '知道了',
				showClose:false,
				callback: action => {}
			});
		},
		readExcel(file){
			return new Promise((resolve,reject)=>{
				const fileReader = new FileReader();

				fileReader.readAsBinaryString(file);

				fileReader.onload = (ev) => {
					try {
						const data = ev.target.result;
						// 切换为新的调用方式
						const workbook = read(data, {
							type: 'binary'
						});
						// 取第一张表
						const wsname = workbook.SheetNames[0];
						// 切换为新的调用方式 生成json表格内容
						// let ws = utils.sheet_to_json(workbook.Sheets[wsname]);
						let ws2 = utils.sheet_to_json(workbook.Sheets[wsname],{ header: 1 });

						// console.log(ws2)
						// return;
						// let ws3 = utils.sheet_to_json(workbook.Sheets[wsname],{ header: 'A' });

						let _arr = [];
						for (let j=2;j < ws2.length;j++) {
							let _json = {};
							let _subIndex = 0;
							let _sub = {};

							if(ws2[j].length <= 1){
								continue;
							}
							for(let i=0;i < ws2[1].length;i++){
								if (typeof ws2[0][i] !== 'undefined' && typeof ws2[1][i] === 'undefined') {
									_json[this.column[ws2[0][i]]] = ws2[j][i];
								} else if(typeof ws2[0][i] !== 'undefined' && typeof ws2[1][i] !== 'undefined'){
									// console.log(i);

									// console.log(typeof _json[this.column[ws2[0][i]]]);

									if(!this.$utils.isNullObject(_sub)){
										if(typeof _json[this.column[ws2[0][_subIndex]]] === 'undefined'){
											_json[this.column[ws2[0][_subIndex]]] = [];
										}
										// _json[this.column[ws2[0][_subIndex]]] = [];
										// console.log(j);
										// console.log(_sub);
										// console.log(_subIndex);
										_json[this.column[ws2[0][_subIndex]]].push(_sub);
									}
									_subIndex = i;
									_sub = {};
									if(!this.$utils.isUndefined(ws2[j][i])) {
										_sub[this.column[ws2[1][i]]] = ws2[j][i];
									}

									// if(typeof _json[this.column[ws2[0][i]]] === 'undefined'){
									// 	if(!this.$utils.isNullObject(_sub)){
									// 		_json[this.column[ws2[0][_subIndex]]].push(_sub);
									// 	}
									//
									// 	_subIndex = i;
									// 	_json[this.column[ws2[0][i]]] = [];
									// }
									//
									// if(!this.$utils.isNullObject(_sub)){
									// 	_json[this.column[ws2[0][_subIndex]]].push(_sub);
									// }

									// _subIndex = i;
									// _sub = {};
									// _sub[this.column[ws2[1][i]]] = ws2[j][i];
								} else if(typeof ws2[0][i] === 'undefined' && typeof ws2[1][i] !== 'undefined'){
									if(!this.$utils.isUndefined(ws2[j][i])) {
										_sub[this.column[ws2[1][i]]] = ws2[j][i];
									}

									if(i === ws2[1].length - 1){
										if(!this.$utils.isNullObject(_sub)){
											if(typeof _json[this.column[ws2[0][_subIndex]]] === 'undefined'){
												_json[this.column[ws2[0][_subIndex]]] = [];
											}
											_json[this.column[ws2[0][_subIndex]]].push(_sub);
										}
									}
								}
							}
							_arr.push(_json);
						}
						resolve(_arr);
					} catch (e) {
						console.log(e);
						reject(false);
						// return false;
					}
				};
			})
		},
		toCheck(){
			this.doCheck(this.formData.list);
		},
		doCheck(data){
			let _this = this;
			this.$http.request({
				url:'/Program/checkImport',
				datas:data,
				success(res){
					_this.formData.list = res;

					let _canSubmit = true;

					for(let i=0;i< res.length;i++){
						if(res[i].error){
							_canSubmit = false;
							_this.$nextTick(()=>{
								_this.$refs['list'].toggleRowExpansion(res[i],true)
							})
						}
					}
					_this.canSubmit = _canSubmit;
				}
			})
		},
		beforeUpload(file){
			let _this = this;
			this.readExcel(file).then((data)=>{
				console.log(data);
				_this.doCheck(data);
			}).catch((err)=>{
				console.log(err);
			});
			return false;
		}
	}
}
</script>
